var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
import Glide from "@glidejs/glide";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    console.log("GLIDE INIT", Glide);
    console.log("[offer_list::redfin::slider::initialize]");
  }
  connect() {
    console.log("[offer_list::redfin::slider::connect]");
    this.initializeGlide();
  }
  initializeGlide() {
    const glideElement = this.element.querySelector(".glide");
    if (glideElement) {
      let breakpoints = {
        1400: {
          perView: 4
        },
        1024: {
          perView: 3
        },
        600: {
          perView: 1
        }
      };
      const TranslateTransformer = (Glide2, Components) => {
        return {
          modify: function(_translate) {
            const gap = Components.Gaps.value;
            const slideWidth = 282;
            const currentIndex = Glide2.index;
            return (slideWidth + gap) * currentIndex;
          }
        };
      };
      const glide = new Glide(glideElement, {
        type: "slider",
        startAt: 0,
        perView: 1,
        gap: 30,
        focusAt: 0,
        bound: false,
        breakpoints
      });
      glide.mutate([TranslateTransformer]).mount();
    }
  }
}
__publicField(component_controller_default, "values", {
  step: Number,
  stepIndex: Number
});
__publicField(component_controller_default, "targets", ["card"]);
