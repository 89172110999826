var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "handleMakesChange", (e) => {
      const makesId = e.target.value;
      this.submitButtonTarget.dataset.text = e.target.selectedOptions[0].text + " ";
      makesId === "" ? this.clearOptions(this.modelsSelectTarget) : this.populateModels(makesId);
    });
    __publicField(this, "handleModelChange", (e) => {
      this.submitButtonTarget.dataset.text += e.target.selectedOptions[0].text;
    });
    __publicField(this, "populateMakes", (year = "") => {
      const context = this;
      const url = "/api/v1/vehicles/makes?year=" + year;
      const placeholder = this.makesPlaceholderTarget;
      placeholder.textContent = "Loading makes...";
      this.clearOptions(this.makesSelectTarget);
      fetch(url).then(function(response) {
        return response.json();
      }).then(function(data) {
        placeholder.textContent = "Select makes";
        if (data.errors || data.length === 0) {
          context.fallbackModeValue = true;
          console.log("CARS API ERROR", data.errors);
        } else {
          context.createOptionsFromData(data, context.makesSelectTarget, "id", "name");
        }
      });
    });
    __publicField(this, "populateModels", (makesId) => {
      const context = this;
      const url = `/api/v1/vehicles/makes/${makesId}/models/?year=${""}`;
      const placeholder = this.modelsPlaceholderTarget;
      placeholder.textContent = "Loading models...";
      this.clearOptions(this.modelsSelectTarget);
      fetch(url).then(function(response) {
        return response.json();
      }).then(function(data) {
        context;
        placeholder.textContent = "Choose a model";
        if (data.errors || data.length === 0) {
          context.fallbackModeValue = true;
        } else {
          context.createOptionsFromData(data, context.modelsSelectTarget, "id", "name");
        }
      });
    });
    __publicField(this, "clearOptions", (selectEl) => {
      const currentOptions = [...selectEl.options];
      currentOptions.map((o) => {
        if (!o.classList.contains("placeholder"))
          o.remove();
      });
    });
    __publicField(this, "createOptionsFromData", (data, selectEl, valueParam, textParam) => {
      data.map(function(item) {
        let option = document.createElement("option");
        option.value = item[valueParam];
        option.text = item[textParam];
        selectEl.appendChild(option);
        return option;
      });
    });
  }
  initialize() {
    console.log("Answer::CarSelectController[index]", this.element);
    this.fallbackModeValue = false;
    this.populateMakes();
  }
  connect() {
    console.log("Answer::CarSelectController[connect]");
  }
  handleSubmit(e) {
    e.preventDefault();
    const handleAnswer = (target) => {
      this.answerController.getAnswerData(target);
      this.answerController.handleAnswer();
    };
    if (this.fallbackModeValue) {
      if (this.fallbackInputTarget.reportValidity())
        handleAnswer(e.target);
    } else if (this.makesSelectTarget.reportValidity() && this.modelsSelectTarget.reportValidity()) {
      handleAnswer(e.target);
    }
  }
  handleFallbackInput(event) {
    const input = event.target;
    this.submitButtonTarget.dataset.text = input.value;
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
  // hooks
  fallbackModeValueChanged() {
    console.log("HOOK");
    if (this.fallbackModeValue) {
      this.makesSelectTarget.classList.add("hidden");
      this.modelsSelectTarget.classList.add("hidden");
      this.fallbackInputTarget.classList.remove("hidden");
    }
  }
}
__publicField(component_controller_default, "targets", ["makesSelect", "modelsSelect", "makesPlaceholder", "modelsPlaceholder", "fallbackInput", "submitButton"]);
__publicField(component_controller_default, "values", { fallbackMode: Boolean });
