var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
const trackerModuleInst = new EventTrackerModule(ahoy);
export class MoneylionMarketplaceModule {
  constructor() {
    this.submitLeadURL = `${window.location.origin}/api/v1/moneylion_marketplace/send_lead`;
    this.internalLeadURL = `${window.location.origin}/leads`;
  }
  // string -> enum
  computeLoanType(questionData) {
    let loanAnswer = questionData.loan_purpose || "Debt Consolidation";
    if (questionData.fifty_k_debt_relief === "Yes") {
      loanAnswer = "Debt Consolidation";
    }
    switch (loanAnswer) {
      case "Debt Consolidation":
        return "DEBT_CONSOLIDATION";
      case "Large Purchase":
        return "LARGE_PURCHASES";
      case "Home Improvement":
        return "HOME_IMPROVEMENT";
      case "CC Refinance":
        return "CREDIT_CARD_REFI";
      case "Student Loan Refinance":
        return "STUDENT_LOAN_REFI";
      case "Special Occasion":
        return "SPECIAL_OCCASION";
      case "Student":
        return "STUDENT_LOAN";
      case "Auto":
        return "AUTO";
      case "Auto Refinance":
        return "AUTO_REFINANCE";
      case "Baby":
        return "BABY";
      case "Wedding":
        return "WEDDING";
      case "Boat":
        return "BOAT";
      case "Business":
        return "BUSINESS";
      case "Green Loan":
        return "GREEN";
      case "Household Expenses":
        return "HOUSEHOLD_EXPENSES";
      case "Medical and Dental":
        return "MEDICAL_DENTAL";
      case "Moving and Relocation":
        return "MOVING_RELOCATION";
      case "Taxes":
        return "TAXES";
      case "Vacation":
        return "VACATION";
      case "Other":
        return "OTHER";
      default:
        return "Debt Consolidation";
    }
  }
  computeState(state) {
    return state;
  }
  computeCreditRating(rating) {
    const parseRating = (rating2) => {
      switch (rating2) {
        case "Limited / No History":
          return "LIMITED";
        case "Poor (<620)":
          return "POOR";
        case "Fair (620 - 659)":
          return "FAIR";
        case "Good (660 - 719)":
          return "GOOD";
        case "Excellent (720+)":
          return "EXCELLENT";
        default:
          return "";
      }
    };
    const randomizeFair = () => {
      const variants = Array(7).fill("POOR").concat(Array(3).fill("FAIR"));
      return variants[Math.floor(Math.random() * variants.length)];
    };
    let ratingValue = parseRating(rating);
    if (ratingValue === "POOR")
      ratingValue = randomizeFair();
    return ratingValue;
  }
  computePayFrequency(frequency) {
    switch (frequency) {
      case "Once every other week":
        return "BIWEEKLY";
      case "Irregularly":
        return "IRREGULARLY";
      case "Once per month":
        return "MONTHLY";
      case "Single":
        return "SINGLE";
      case "Twice per month":
        return "TWICE_MONTHLY";
      case "Once every week":
        return "WEEKLY";
      default:
        return "";
    }
  }
  computeEmploymentStatus(status) {
    switch (status) {
      case "Employed":
        return "EMPLOYED";
      case "Military":
        return "MILITARY";
      case "Not Employed":
        return "NOT_EMPLOYED";
      case "Retired":
        return "RETIRED";
      case "Self Employed":
        return "SELF_EMPLOYED";
      case "Other":
        return "OTHER";
      default:
        return "";
    }
  }
  computePropertyStatus(status) {
    switch (status) {
      case "Own":
        return "OWN_OUTRIGHT";
      case "Own with Mortgage":
        return "OWN_WITH_MORTGAGE";
      case "Rent":
        return "RENT";
      default:
        return "";
    }
  }
  computeEducationLevel(level) {
    switch (level) {
      case "Associate's Degree":
        return "ASSOCIATE";
      case "Bachelor's Degree":
        return "BACHELORS";
      case "Doctorate":
        return "DOCTORATE";
      case "High School Diploma":
        return "HIGH_SCHOOL";
      case "Master's Degree":
        return "MASTERS";
      case "Other Graduate Degree":
        return "OTHER_GRAD_DEGREE";
      default:
        return "OTHER";
    }
  }
  computeDirectDepositStatus(status) {
    switch (status) {
      case "yes":
        return true;
      default:
        return false;
    }
  }
  convertStringToInt(str) {
    if (str) {
      return Number(str.replace(/[^0-9.-]+/g, ""));
    }
  }
  computeLoanAmount(questionData) {
    let loanAmount = this.convertStringToInt(questionData.loan_amount || "0");
    if (questionData.fifty_k_debt_relief === "Yes") {
      loanAmount = 5e4;
    }
    const additionalAmount = questionData.additional_loan === "Yes";
    if (additionalAmount)
      loanAmount += 1e4;
    return loanAmount;
  }
  sendLead(enableRedirect = true) {
    return __async(this, null, function* () {
      this.locationData = JSON.parse(document.body.dataset.sessionLocation || {});
      this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}");
      this.questionData = JSON.parse(localStorage.getItem("questions_results") || "{}");
      const urlParams = new URLSearchParams(window.location.search);
      const ltidFromStorage = window.localStorage.getItem("ltid");
      const ltidString = typeof ltidFromStorage == "string" ? String(window.localStorage.getItem("ltid")).replaceAll('"', "") : "no_ltid";
      const ltid = (ltidString == null ? void 0 : ltidString.length) > 0 ? ltidString : "no_ltid_string";
      const data = {
        moneylion_marketplace: {
          loan_information: {
            amount: this.computeLoanAmount(this.questionData),
            purpose: this.computeLoanType(this.questionData)
          },
          user_information: {
            id: ltid,
            first_name: this.userData.first_name,
            last_name: this.userData.last_name,
            ssn: this.questionData.social,
            email: this.userData.email,
            phone_number: this.userData.phone,
            date_of_birth: this.questionData.date_of_birth,
            address: {
              address_line_1: this.questionData.address,
              address_line_2: "",
              city: this.userData.city,
              country: this.userData.country || "USA",
              postal_code: this.questionData.zip,
              state: this.computeState(this.userData.state)
            },
            annual_income: this.convertStringToInt(this.questionData.annual_income),
            credit_rating: this.computeCreditRating(this.questionData.credit_rating),
            has_direct_deposit: this.computeDirectDepositStatus(this.questionData.has_direct_deposit),
            education_level: this.computeEducationLevel(this.questionData.education_level),
            employment_status: this.computeEmploymentStatus(this.questionData.employment_status),
            pay_frequency: this.computePayFrequency(this.questionData.pay_frequency),
            property_status: this.computePropertyStatus(this.questionData.property_status)
          }
        }
      };
      trackerModuleInst.track("moneylion_send_lead_req_obj", {
        ltid,
        request_data: data,
        url_params: window.location.search,
        page: window.location.pathname,
        url: window.location.href
      });
      const response = yield fetch(this.submitLeadURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(function(res) {
        return res;
      });
      console.log("RESPONSE::", response);
      let result = yield response.json();
      trackerModuleInst.track("moneylion_send_lead_response_result", {
        ltid,
        result,
        url_params: window.location.search,
        page: window.location.pathname,
        url: window.location.href
      });
      console.log("RESULT::", result);
      result = result.data || result.errors || {};
      window.localStorage.setItem("savedRedirect", "https://marketplace-web.moneylion.com/personal-loan?traffic_src=leadgen&medium=lgd&tag.subid1=20755");
      if (response.status === 200) {
        const redirectURL = `https://marketplace-web.moneylion.com/personal-loan?traffic_src=leadgen&medium=lgd&tag.subid1=20755&tag.subid3=${urlParams.get(
          "sub1"
        )}&transaction_id=${result.userInformation.id}`;
        if (redirectURL && enableRedirect) {
          window.location.href = redirectURL;
        } else {
          window.localStorage.setItem("savedRedirect", redirectURL);
        }
        return { result };
      } else {
        this.renderError(Object.values(result));
        console.log("ERR RESPONSE::", response);
        return {
          error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
          errors: result["errors"]
        };
      }
    });
  }
  saveExternalId(externalId) {
    return __async(this, null, function* () {
      const response = yield fetch(this.internalLeadURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          lead: {
            external_storage_token: externalId
          }
        })
      }).then(function(res) {
        return res;
      });
      let result = yield response.json();
      result = result.data || result.errors || {};
      console.log("saveExternalId:RESULT::", result);
      if (response.status === 200) {
        return { result };
      } else {
        console.log("saveExternalId:ERR RESPONSE::" + externalId, response);
        return {
          error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
          errors: result["errors"]
        };
      }
    });
  }
  renderError(messages) {
    console.log("Moneylion errors", messages);
    const messageItems = messages.map((message) => {
      return `<li>${JSON.stringify(message)}</li>`;
    });
    const errorTemplate = `
    <div class="w-full text-white bg-red-500 error-box fixed top-0 left-0">
        <div class="container flex items-center justify-between flex-wrap px-6 py-4 mx-auto">
            <div class="flex w-full">
              <svg viewBox="0 0 40 40" class="w-6 h-6 fill-current">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"></path>
              </svg>
              <p class="mx-3">Sorry, something went wrong.</p>
            </div>
            <ul class="w-full pl-12 mt-2 list-disc">
              ${messageItems.join("")}
            </ul>
        </div>
    </div>
    `;
    console.log("Moneylion errorsTemp", errorTemplate);
    document.body.insertAdjacentHTML("beforeend", errorTemplate);
  }
}
